<template>
  <b-row>
    <b-col md="12">
      <b-card-code
        title=""
        no-body
      >
        <b-tabs
          v-model="tabIndex"
          align="center"
          pills
        >

          <b-tab
            title="Upcoming"
            href="#upcoming"
          >

            <!-- upcoming table -->
            <warehouse-upcoming-table
              :items="upcomings"
              :facilities="facilities"
              @save="save"
              @confirm="confirm"
              @filterByFacility="filterByFacility"
              @filterByType="filterByType"
              @filterByDate="filterByDate"
            />

          </b-tab>

          <b-tab
            title="Confirmed"
            href="#confirmed"
          >

            <!-- confirmed table -->
            <warehouse-confirmed-table
              :items="confirms"
              :facilities="facilities"
              @save="save"
              @filterByFacility="filterByFacility"
              @filterByType="filterByType"
              @filterByDate="filterByDate"
            />

          </b-tab>

          <b-tab
            title="Package list/Container list"
            href="#container"
          >

            <warehouse-package-table />

          </b-tab>
          
        </b-tabs>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import * as warehouseService from '@/services/warehouse'
import * as facilityService from '@/services/facility'
import WarehouseUpcomingTable from './UpcomingTable.vue'
import WarehouseConfirmedTable from './ConfirmedTable.vue'
import WarehousePackageTable from './PackageTable.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BTabs,
    BTab,
    WarehouseUpcomingTable,
    WarehouseConfirmedTable,
    WarehousePackageTable,
  },
  data() {
    return {
      upcomings: [],
      confirms: [],
      warehouses: [],
      facilities: [],
      tabIndex: 0,
    }
  },
  created() {
    this.getWarehouses()
    this.getFacility()
  },
  methods: {
    getWarehouses() {
      this.$store.commit('set_loading_action', true)
      warehouseService.getWarehouses()
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.warehouses = res.data.warehouses
          this.upcomings = res.data.warehouses.filter(v => v.status !== 'Confirmed Freight')
          this.confirms = res.data.warehouses.filter(v => v.status === 'Confirmed Freight')
        })
        .catch(() => {
          this.$store.commit('set_loading_action', false)
        })
    },

    // save actual value data
    save(data) {
      this.$store.commit('set_loading_action', true)
      warehouseService.saveActual(data)
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.getWarehouses()
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // confirm freight
    confirm(id) {
      this.$store.commit('set_loading_action', true)
      warehouseService.confirm(id)
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.getWarehouses()
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // filter by facility
    filterByFacility(facility) {
      if (facility === null) {
        this.upcomings = this.warehouses.filter(v => v.status !== 'Confirmed Freight')
      } else {
        this.upcomings = this.warehouses.filter(v => v.status !== 'Confirmed Freight' && v.facility === facility)
      }
    },

    // filter by action type
    filterByType(type) {
      this.upcomings = this.warehouses.filter(v => v.status !== 'Confirmed Freight' && v.type === type)
    },

    // filter by Date
    filterByDate(date) {
      const { from, to, type } = date

      if (type === 'upcoming') {
        this.upcomings = this.warehouses.filter(v => {
          if (v.status !== 'Confirmed Freight') {
            let checkFrom = true
            let checkTo = true
            if (v.pickup_time && from && Date.parse(v.pickup_time) < Date.parse(from)) {
              checkFrom = false
            } else if (v.pickup_time && to && Date.parse(v.pickup_time) > Date.parse(to)) {
              checkTo = false
            } else if (v.delivery_time && from && Date.parse(v.delivery_time) < Date.parse(from)) {
              checkFrom = false
            } else if (v.delivery_time && to && Date.parse(v.delivery_time) > Date.parse(to)) {
              checkTo = false
            }
            return checkFrom * checkTo
          }
          return false
        })
      } else {
        this.confirms = this.warehouses.filter(v => {
          if (v.status === 'Confirmed Freight') {
            let checkFrom = true
            let checkTo = true
            if (v.pickup_time && from && Date.parse(v.pickup_time) < Date.parse(from)) {
              checkFrom = false
            } else if (v.pickup_time && to && Date.parse(v.pickup_time) > Date.parse(to)) {
              checkTo = false
            } else if (v.delivery_time && from && Date.parse(v.delivery_time) < Date.parse(from)) {
              checkFrom = false
            } else if (v.delivery_time && to && Date.parse(v.delivery_time) > Date.parse(to)) {
              checkTo = false
            }
            return checkFrom * checkTo
          }
          return false
        })
      }
    },

    // get company facilities
    async getFacility() {
      const res = await facilityService.companyFacilities()
      this.facilities = res.data.facilities
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
