<template>
  <b-row class="mt-1">
    <b-col
      md="3"
      class="mb-1"
    >
      <strong>Bol :
                
        <b-link
          v-if="bol"
          :href="bol"
          target="_blank"
          class="text-secondary"
        >
          <b-img
            :src="ImageIcon"
            class="iconType"
          />
        </b-link>
      </strong>
    </b-col>

    <b-col
      md="3"
      class="mb-1"
    >
      <strong>Pod :
                
        <b-link
          v-if="pod"
          :href="pod"
          target="_blank"
          class="text-secondary"
        >
          <b-img
            :src="ImageIcon"
            class="iconType"
          />
        </b-link>
      </strong>
    </b-col>

    <b-col
      md="3"
      class="mb-1"
    >
      <strong>Freight Image :
                
        <b-link
          v-if="freightImage"
          :href="freightImage"
          target="_blank"
          class="text-secondary"
        >
          <b-img
            :src="ImageIcon"
            class="iconType"
          />
        </b-link>
      </strong>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BImg, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
  },
  props: {
    bol: {
      type: String,
      default: '',
    },
    pod: {
      type: String,
      default: '',
    },
    freightImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ImageIcon: require('@/assets/images/upload/image.png'),
      DocumentIcon: require('@/assets/images/upload/document.png'),
    }
  },
}
</script>

<style>
.iconType {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
</style>
