<template>
  <b-row>
    <!-- Weight -->
    <b-col md="3">
      <b-form-group
        label="Weight"
        label-for="mc-weight"
      >
        <b-form-input
          id="mc-weight"
          v-model="item.actual_weight"
          type="number"
          placeholder="Weight"
        />
      </b-form-group>
    </b-col>

    <!-- pallets -->
    <b-col md="3">
      <b-form-group
        label="Pallets"
        label-for="mc-pallets"
      >
        <b-form-input
          id="mc-pallets"
          v-model="item.actual_pallets"
          type="number"
          placeholder="Pallets"
        />
      </b-form-group>
    </b-col>

    <!-- spots -->
    <b-col md="3">
      <b-form-group
        label="Spots"
        label-for="mc-spots"
      >
        <b-form-input
          id="mc-spots"
          v-model="item.actual_spots"
          type="number"
          placeholder="Spots"
        />
      </b-form-group>
    </b-col>

    <!-- cases -->
    <b-col md="3">
      <b-form-group
        label="Cases"
        label-for="mc-cases"
      >
        <b-form-input
          id="mc-cases"
          v-model="item.actual_cases"
          type="number"
          placeholder="Cases"
        />
      </b-form-group>
    </b-col>

    <!-- seal -->
    <b-col md="3">
      <b-form-group
        label="Seal"
        label-for="mc-seal"
      >
        <b-form-input
          id="mc-cases"
          v-model="item.seal"
          placeholder="Seal"
        />
      </b-form-group>
    </b-col>

    <b-col md="12">
      <b-button
        variant="primary"
        @click="save"
      >
        Save
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    save() {
      const data = {
        id: this.item.id,
        actual_weight: this.item.actual_weight,
        actual_pallets: this.item.actual_pallets,
        actual_spots: this.item.actual_spots,
        actual_cases: this.item.actual_cases,
        seal: this.item.seal,
      }

      this.$emit('save', data)
    },
  },
}
</script>

<style>

</style>
