<template>
  <div>
    <div style="padding: 0rem 1rem">
      <b-button
        variant="success"
        @click="openModal()"
      >
        Create+
      </b-button>
    </div>
    <b-card-body style="padding: 1rem">
      <b-row>
        <b-col md="2">
          <!-- filter by facility -->
          <b-form-group class="mb-0">
            <v-select
              v-model="facility"
              :options="facilities"
              label="name"
              :reduce="facility => facility.name"
              placeholder="Facility"
              class="w-100"
              append-to-body
              @input="filterByFacility"
            >
              <template
                #option="{ id, name }"
                :index="id"
              >
                {{ name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <!-- start date -->
          <b-form-group
            class="mb-0"
            label="From"
            label-cols-sm="2"
            content-cols-sm
          >
            <b-form-datepicker
              id="fromDate"
              v-model="fromDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              show-decade-nav
              locale="en-US"
              aria-controls="example-input"
              @input="filterByDate"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <!-- to Date -->
          <b-form-group
            class="mb-0"
            label="To"
            label-cols-sm="2"
            content-cols-sm
          >
            <b-form-datepicker
              id="toDate"
              v-model="toDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              show-decade-nav
              locale="en-US"
              aria-controls="example-input"
              @input="filterByDate"
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <!-- filter by action type -->
          <b-button-group>
            <b-button
              variant="danger"
              @click="filterByType('unLoading')"
            >
              Pickup
            </b-button>
            <b-button
              variant="success"
              @click="filterByType('Loading')"
            >
              Delivery
            </b-button>
          </b-button-group>
        </b-col>

        <b-col md="2">

          <!-- filter -->
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(show_details)="row">
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <feather-icon
          class="mr-1"
          size="18"
          :icon="`${row.detailsShowing ? 'RefreshCcw' : 'Edit2'}Icon`"
          @click="row.toggleDetails"
        />
      </template>

      <!-- full detail on click -->
      <template #row-details="row">
        <warehouse-table-form
          :item="row.item"
          @save="save"
        />

        <hr class="w-100">

        <warehouse-freight-form
          :bol="row.item.bol"
          :pod="row.item.pod"
          :freight-image="row.item.freight_image"
        />
      </template>

      <!-- status -->
      <template #cell(status)="row">
        <b-badge
          :variant="row.item.status === 'Booked' ? 'warning' : 'success'"
          class="px-1 py-1"
        >
          {{ row.item.status }}
        </b-badge>
      </template>

      <!-- type -->
      <template #cell(type)="row">
        <b-badge
          :variant="row.item.type === 'Loading' ? 'warning' : 'primary'"
          class="px-1 py-1"
        >
          {{ row.item.type }}
        </b-badge>
      </template>

      <!-- pickup time -->
      <template #cell(pickup_time)="row">
        {{ row.item.pickup_time | datePipe }}
      </template>

      <!-- delivery time -->
      <template #cell(delivery_time)="row">
        {{ row.item.delivery_time | datePipe }}
      </template>

      <!-- weight -->
      <template #cell(weight)="row">
        {{ row.item.estimated_weight }} / {{ row.item.actual_weight }}
      </template>

      <!-- pallets -->
      <template #cell(pallets)="row">
        {{ row.item.estimated_pallets }} / {{ row.item.actual_pallets }}
      </template>

      <!-- pallets -->
      <template #cell(cases)="row">
        {{ row.item.estimated_cases }} / {{ row.item.actual_cases }}
      </template>

      <!-- pickup_driver -->
      <template #cell(pickup_driver)="row">
        <span v-if="row.item.type === 'unLoading'">
          {{ `${row.item.firstname} ${row.item.lastname}` }} / {{ row.item.truck }} / {{ row.item.trailer }}
        </span>
      </template>

      <!-- delivery_driver -->
      <template #cell(delivery_driver)="row">
        <span v-if="row.item.type === 'Loading'">
          {{ `${row.item.firstname} ${row.item.lastname}` }} / {{ row.item.truck }} / {{ row.item.trailer }}
        </span>
      </template>

      <!-- action -->
      <template #cell(action)="row">
        <b-button
          variant="primary"
          @click="confirm(row.item.id)"
        >
          Confirm
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <package-create-modal />
  </div>
</template>

<script>
import {
  BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButtonGroup, BButton, BCardBody, BBadge, BFormDatepicker, BRow, BCol, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import WarehouseTableForm from './TableForm.vue'
import WarehouseFreightForm from './FreightForm.vue'
import PackageCreateModal from './PackageCreateModal.vue'

export default {
  components: {
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButtonGroup,
    BButton,
    BCardBody,
    BBadge,
    BFormDatepicker,
    BRow,
    BCol,
    vSelect,
    WarehouseTableForm,
    WarehouseFreightForm,
    PackageCreateModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    datePipe(date) {
      if (date === null) return ''
      return moment(date).format('MM/DD/yyyy HH:mm')
    },
    commodity(data) {
      const array = data.filter((v, i) => data.indexOf(v) === i)
      return array.join()
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    facilities: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      facility: null,
      fromDate: null,
      toDate: null,
      filterOn: [],
      fields: [
        'show_details',
        { key: 'type', label: 'Type', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'pickup_driver', label: 'Pickup Driver/Truck/Trailer', sortable: true },
        { key: 'pickup_time', label: 'Pickup Time' },
        { key: 'pickup_address', label: 'Pickup Address', sortable: true },
        { key: 'load_id', label: 'LoadID', sortable: true },
        { key: 'delivery_address', label: 'Delivery Address', sortable: true },
        { key: 'delivery_time', label: 'Delivery Time' },
        { key: 'delivery_driver', label: 'Delivery Driver/Truck/Trailer', sortable: true },
        { key: 'commodity', label: 'Commodity', tdClass: 'short-column' },
        { key: 'weight', label: 'E/A Weight', tdClass: 'short-column' },
        { key: 'pallets', label: 'E/A Pallets', tdClass: 'short-column' },
        { key: 'seal', label: 'Seal#', tdClass: 'short-column' },
        {
          key: 'number', label: 'Number', sortable: true, tdClass: 'short-column',
        },
        { key: 'reference', label: 'Reference', tdClass: 'short-column' },
        // { key: 'action', label: 'Action' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    items(value) {
      this.totalRows = value.length
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length

    const curr = new Date()
    this.fromDate = moment(curr).format('YYYY-MM-DD')
    this.toDate = moment(curr).format('YYYY-MM-DD')
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    confirm(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('confirm', id)
        }
      })
    },

    save(data) {
      this.$emit('save', data)
    },

    filterByFacility(facility) {
      this.$emit('filterByFacility', facility)
    },

    filterByType(type) {
      this.$emit('filterByType', type)
    },

    filterByDate() {
      const date = {
        from: this.fromDate,
        to: this.toDate,
        type: 'upcoming',
      }

      this.$emit('filterByDate', date)
    },

    openModal() {
      this.$bvModal.show('package-modal')
    },
  },
}
</script>
<style lang="scss">
  .short-column {
    max-width: 70px;
  }
</style>
