<template>
  <b-modal
    id="package-modal"
    centered
    title="Package Create/Edit"
    :no-close-on-backdrop="true"
    @hidden="resetModal"
    @ok="sumbit"
  >
    <b-form>
      <div class="d-flex justify-content-between">
        <b-button
          pill
          :variant="true ? 'flat-success' : 'flat-secondary'"
          @click="chooseType('rail')"
        >
          <feather-icon
            icon="CheckIcon"
            size="18"
          />
          Rail
        </b-button>

        <b-button
          pill
          :variant="true ? 'flat-success' : 'flat-secondary'"
          @click="chooseType('driving')"
        >
          <feather-icon
            icon="CheckIcon"
            size="18"
          />
          Driving
        </b-button>
      </div>
      <b-form-group>
        <b-form-input />
      </b-form-group>

      <b-form-group
        label="Load Lists"
        label-for="load_lists"
      >
        <v-select />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BForm, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  methods: {
    resetModal() {

    },

    sumbit() {

    },

    chooseType(val) {
      console.log(val)
    },
  },
}
</script>

<style>

</style>
